import { COMPANY_NAME } from '../../../config'

const FooterBottom = () => {
    return (
        <div className="text-center border-t border-black/25 py-2">
            {COMPANY_NAME} &copy; {new Date().getFullYear()}
        </div>
    )
}

export default FooterBottom
