import { useTranslation } from 'react-i18next'
import { strings } from '../utils/internationalization/strings'

const useTranslate = () => {
    const { t } = useTranslation()
    const translate = t
    return { translate, strings }
}

export default useTranslate
