import { FC } from 'react'

type SpacerType = {
    spacing?: number
}

const Spacer: FC<SpacerType> = ({ spacing = 50 }: SpacerType) => {
    return <div style={{ height: `${spacing}px` }} />
}

export default Spacer
