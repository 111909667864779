import { IconType } from 'react-icons'
// import { Link } from 'react-router-dom'
import { Button, Modal } from 'antd';
import { useState } from 'react';
import PaymentPolicy from '../policies/PaymentPolicy';
import PrivacyPolicy from '../policies/PrivacyPolicy';
import TermsAndConditions from '../policies/TermsAndConditions';
import Aboutus from '../policies/Aboutus';


type ItemLinkProps = {
    title?: string
    link?: string
}

type ContactLinkProps = ItemLinkProps & {
    value: string
}

type IconLinkProps = ItemLinkProps & {
    Icon: IconType
}



export const SupportLink = ({ title }: ItemLinkProps) => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    }

    const cloaseModal = () => {
        setShowModal(false);
    }

    const getContent = () => {
        switch (title) {
            case 'About Us':
                return <Aboutus />
            case 'Privacy Policy':
                return <PrivacyPolicy />
            case 'Terms & Condition':
                return <TermsAndConditions />
            case 'Payment & Refund Policy':
                return <PaymentPolicy />
            default:
                return 'Oops something went wrong..!! We get back soon...!!'

        }
    }

    return (
        <>
        <Button type='link' className="flex mb-2" onClick={openModal}>
           {title}
        </Button>
        
        <Modal width={800}  open={showModal} onCancel={cloaseModal} onOk={cloaseModal} footer={[
          <Button key="ok" type="primary" onClick={cloaseModal}>
            Thanks for reading
          </Button>
        ]} >
         {getContent()}
        </Modal>
        </>
    )
}

export const ContactLink = ({ link, title, value }: ContactLinkProps) => {
    return (
        <div className="mb-2">
            {title ? <div className="text-black/70">{title}</div> : <></>}
            <a href={link} target="_blank" rel="noreferrer" title={value}>
                <div className="text-lg font-semibold text-main ">{value}</div>
            </a>
        </div>
    )
}

export const IconLink = ({ link, title, Icon }: IconLinkProps) => {
    return (
        <>
            <a href={link} target="_blank" rel="noreferrer" title={title}>
                <Icon className="text-3xl text-black/70 hover:text-main transition" />
            </a>
        </>
    )
}
