import { COMPANY_NAME } from "../config";
import bannerImage from '../assets/images/banner1_v5.jpg'



const SingleImage = () => {
  return (
    <div className="relative w-full h-[50dvh] sm:h-[90dvh]" >
      <img src={bannerImage} alt={`img-banner`} className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-black opacity-30 group-hover:opacity-70 transition-opacity"></div>
      <div className="absolute inset-0 flex justify-center items-center text-center">
        <p className="font-mono text-white text-9xl max-sm:text-5xl font-bold">{COMPANY_NAME}</p>
      </div>
    </div>
  );
};

export default SingleImage;
