import React from "react";
import ReactDOM from "react-dom/client";

// Components
import App from "./App";
import { CommonWrapper } from "./common/wrapper";

// Utils
import { firebaseApp } from "./utils/firebase";
import "./utils/internationalization";

// CSS
import "./index.css";

// firebase setup initialization
firebaseApp()

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CommonWrapper>
      <App />
    </CommonWrapper>
  </React.StrictMode>
);
