import { useState, MouseEvent } from 'react'
// import { ChangeEvent } from 'react'
import dayjs from 'dayjs'

// components
import type { DatePickerProps, InputNumberProps } from 'antd'
import { DatePicker, InputNumber, Select, Input } from 'antd'

// icons
import { FiSearch } from 'react-icons/fi'
// import { IoMdLocate } from 'react-icons/io'

// hooks
import { usePlacesWidget } from 'react-google-autocomplete'
import { useFirebaseDB, useToast } from '../../../hook'

// config
import { GOOGLE_MAPS_API_KEY } from '../../../config'

// utils
import { getCurrentDateTime } from '../../../utils/date-time'

// css
import './events-search-bar.css'

type FormProps = {
    occasion: string | null
    date: string
    place?: string
    noOfGuest: number
    mobileNo: number | string
    location: string
}

type SaveDataFormProps = FormProps & { createdAt: string }

const OCCASION_LIST = [
    { label: 'Birthday', value: 'birthday' },
    { label: 'Cradel Ceremony', value: 'cradel-ceremony' },
    { label: 'House Warming', value: 'house-warming' },
    { label: 'Baby Shower', value: 'baby-shower' },
    { label: 'Anniversary', value: 'anniversary' },
]

const EventsSearchBar = () => {
    const initialState = {
        occasion: OCCASION_LIST[0].value,
        date: '',
        place: 'Bengaluru',
        location: 'Bengaluru',
        noOfGuest: 1,
        mobileNo: '',
    }

    const [form, setForm] = useState<FormProps>(initialState)
    const { inquiriesRef, get, set } = useFirebaseDB()
    const { successToast, errorToast } = useToast()
    const { ref } = usePlacesWidget({
        apiKey: GOOGLE_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            setForm((prev) => ({
                ...prev,
                location: place?.formatted_address,
                place: place?.formatted_address,
            }))
        },
    })

    const resetForm = () => {
        setForm(initialState)
    }

    const onOccasionChange = (value: string) => {
        setForm((prev) => ({ ...prev, occasion: value }))
    }

    // const onLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     setForm((prev) => ({ ...prev, place: e.target.value }))
    // }

    const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
        // @ts-ignore
        setForm((prev) => ({ ...prev, date: dateString }))
    }

    const onGuestChange: InputNumberProps['onChange'] = (value) => {
        setForm((prev) => ({ ...prev, noOfGuest: +(value || 1) }))
    }

    const onMobileNoChange: InputNumberProps['onChange'] = (value) => {
        setForm((prev) => ({ ...prev, mobileNo: value || '' }))
    }

    const onSubmitclick = async (e: MouseEvent) => {
        e.preventDefault()
        const { place, ...data } = form
        const currentDateTime = getCurrentDateTime();
        console.log(data, 'submit click');
        try {
            const snapshot = await get(inquiriesRef)
            if (snapshot.exists()) {
                saveAndResetForm([...snapshot.val(), { ...data, createdAt: currentDateTime }])
            } else {
                saveAndResetForm([{ ...data, createdAt: currentDateTime }])
            }
        } catch (error) {
            errorToast('Some Error Occured! Please Try Again Later.')
        }
    }

    const saveAndResetForm = (data: SaveDataFormProps[]) => {
        console.log(data);
        set(inquiriesRef, data)
        resetForm()
        successToast('Your Inquriy Raised Successfully')
    }

    // const geolocationErrorCallback = (error: GeolocationPositionError) => {
    //     switch (error.code) {
    //         case error.PERMISSION_DENIED:
    //             errorToast('User denied the request for Geolocation.')
    //             break
    //         case error.POSITION_UNAVAILABLE:
    //             errorToast('Location information is unavailable.')
    //             break
    //         case error.TIMEOUT:
    //             errorToast('The request to get user location timed out.')
    //             break
    //         default:
    //             errorToast('An unknown error occurred.')
    //             break
    //     }
    // }

    // const geoLocationSuccessCallback = (val: GeolocationPosition) => {
    //     const {
    //         coords: { latitude, longitude },
    //     } = val
    //     const api = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
    //     fetch(api)
    //         .then((res) => res.json())
    //         .then((data) => {
    //             const address = data.results[0].formatted_address
    //             setForm((prev) => ({ ...prev, place: address, location: address }))
    //         })
    //         .catch((err) => {
    //             errorToast(err.message)
    //         })
    // }

    // const handleLocateMe = () => {
    //     if ('geolocation' in navigator) {
    //         navigator.geolocation.getCurrentPosition(
    //             geoLocationSuccessCallback,
    //             geolocationErrorCallback
    //         )
    //     } else {
    //         errorToast("Your Browser don't have location service.")
    //     }
    // }

    return (
        <div className="rounded-lg p-3 bg-white shadow-md text-left">
            <div className="flex max-md:gap-2 md:gap-4 pl-2 max-md:flex-col h-full">
                {/* Occasion */}
                <div className="flex-1">
                    <p className="text-sm font-semibold mb-1">Occasion</p>
                    <Select
                        value={form.occasion}
                        className="w-full lg:min-w-[140px]"
                        onChange={onOccasionChange}
                        options={OCCASION_LIST}
                        variant="borderless"
                    />
                </div>
                <div className=" bg-black/25 w-full max-md:h-[1px] md:w-[1px] " />

                {/* City of Event */}
                <div className="flex-1">
                    <div className="mb-1 flex justify-between">
                        <p className="text-sm font-semibold ">City</p>
                        {/* Locate me button to fetch current location */}
                        {/* <p
                            className="text-sm text-main hover:text-main/50 cursor-pointer transition flex gap-1 justify-center items-center"
                            onClick={handleLocateMe}
                        >
                            <IoMdLocate />
                            Locate Me
                        </p> */}
                    </div>
                    <Input
                        ref={(c) => {
                            //@ts-ignore
                            if (c) ref.current = c.input
                        }}
                        value={form.place}
                        // onChange={onLocationChange}
                        placeholder="Where are you celebrating?"
                        variant="borderless"
                        className="!px-0"
                        disabled
                    />
                </div>
                <div className=" bg-black/25 w-full max-md:h-[1px] md:w-[1px] " />

                {/* Date of Event */}
                <div className="flex-1">
                    <p className="text-sm font-semibold mb-1">Date</p>
                    <DatePicker
                        onChange={onDateChange}
                        variant="borderless"
                        placeholder="Date of Event"
                        className="px-0 w-full"
                        minDate={dayjs(new Date().toLocaleDateString('en-US'))}
                        value={form.date !== '' ? dayjs(form.date) : null}
                    />
                </div>
                <div className=" bg-black/25 w-full max-md:h-[1px] md:w-[1px] " />

                {/* Number of Guests */}
                <div className="flex-1">
                    <p className="text-sm font-semibold mb-1">No. of Guests</p>
                    <InputNumber
                        min={1}
                        max={10000}
                        defaultValue={form.noOfGuest}
                        value={form.noOfGuest}
                        onChange={onGuestChange}
                        variant="borderless"
                        className="w-full"
                        placeholder="Number of Guests"
                    />
                </div>
                <div className=" bg-black/25 w-full max-md:h-[1px] md:w-[1px] " />

                {/* Mobile Number */}
                <div className="flex-1">
                    <p className="text-sm font-semibold mb-1">Mobile No.</p>
                    <InputNumber
                        defaultValue={form.mobileNo}
                        value={form.mobileNo}
                        onChange={onMobileNoChange}
                        controls={false}
                        variant="borderless"
                        className="w-full"
                        placeholder="Your Mobile Number"
                    />
                </div>

                {/* Search Button */}
                <div className="flex-1">
                    <button
                        onClick={onSubmitclick}
                        className="flex bg-main hover:bg-main/90 text-white rounded-md p-2 h-full w-full items-center justify-center gap-2 text-xl"
                    >
                        {/* <FiSearch /> */}
                        <p className="font-semibold">Submit</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EventsSearchBar
