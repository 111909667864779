import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { INTERNATIONALIZATION_LINK } from '../../config'

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ['en', 'fr', 'de', 'es', 'it'],
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['localStorage', 'cookie', 'sessionStorage', 'navigator'],
            caches: ['localStorage'],
        },
        backend: {
            loadPath: INTERNATIONALIZATION_LINK,
        },
    })
