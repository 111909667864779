import React from 'react';
import { Typography, List } from 'antd';

const { Title, Paragraph, Text } = Typography;

const PrivacyPolicy = () => {
  return (
    <Typography style={{ padding: '20px' }}>
      <Title>Privacy Policy</Title>
      
      <Title level={4}>1. Introduction</Title>
      <Paragraph>
        Qala Events ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you visit our website and use our services. Please read this policy
        carefully. If you do not agree with the terms of this privacy policy, please do not access the site or use our services.
      </Paragraph>
      
      <Title level={4}>2. Information We Collect</Title>
      
      <Title level={5}>2.1 Personal Information</Title>
      <Paragraph>We may collect personal information that you provide to us when you:</Paragraph>
      <List
        dataSource={[
          'Register on our website',
          'Book our services',
          'Subscribe to our newsletter',
          'Contact us for inquiries or support',
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
      <Paragraph>This information may include, but is not limited to:</Paragraph>
      <List
        dataSource={[
          'Name',
          'Email address',
          'Phone number',
          'Mailing address',
          'Payment information (As allowed by RBI)',
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
      
      <Title level={5}>2.2 Non-Personal Information</Title>
      <Paragraph>
        We may also collect non-personal information about you when you interact with our website. This information may include
        your browser type, device type, IP address, location, operating system, and browsing behaviour.
      </Paragraph>
      
      <Title level={4}>3. How We Use Your Information</Title>
      <Paragraph>We use the information we collect for various purposes, including to:</Paragraph>
      <List
        dataSource={[
          'Provide and manage our services',
          'Process your transactions',
          'Communicate with you regarding your bookings and inquiries',
          'Send promotional materials, newsletters, and other information (you can opt-out at any time)',
          'Improve our website and services',
          'Comply with legal obligations',
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
      
      <Title level={4}>4. Sharing Your Information</Title>
      <Paragraph>We may share your information with:</Paragraph>
      <List
        dataSource={[
          'Service providers who assist us in operating our business (e.g., payment processors, email marketing services)',
          'Third parties with whom you have authorized us to share your information',
          'Law enforcement or regulatory agencies if required by law',
          'Other third parties with your consent or as necessary to protect our rights',
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
      
      <Title level={4}>5. Data Security</Title>
      <Paragraph>
        We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure.
        However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute
        security.
      </Paragraph>
      
      <Title level={4}>6. Cookies and Tracking Technologies</Title>
      <Paragraph>
        We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small data files
        stored on your device that help us understand how you use our site and improve its functionality. You can control the use
        of cookies through your browser settings.
      </Paragraph>
      
      <Title level={4}>7. Your Rights</Title>
      <Paragraph>You have certain rights regarding your personal information, including:</Paragraph>
      <List
        dataSource={[
          <><Text strong>Access:</Text> You can request access to the personal information we hold about you.</>,
          <><Text strong>Correction:</Text> You can request corrections to any inaccurate or incomplete personal information.</>,
          <><Text strong>Deletion:</Text> You can request the deletion of your personal information, subject to certain legal obligations.</>,
          <><Text strong>Opt-Out:</Text> You can opt-out of receiving promotional communications from us at any time.</>,
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
      <Paragraph>To exercise these rights, please contact us using the information provided below.</Paragraph>
      
      <Title level={4}>8. Third-Party Links</Title>
      <Paragraph>
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these
        third-party sites. We encourage you to review their privacy policies before providing any personal information.
      </Paragraph>
      
      <Title level={4}>9. Changes to This Privacy Policy</Title>
      <Paragraph>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.
        Your continued use of our website and services after any changes indicates your acceptance of the revised policy.
      </Paragraph>
      
      <Title level={4}>Contact Us</Title>
      <Paragraph>
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </Paragraph>
      <Paragraph>
        <Text strong>Qala Events</Text><br />
        Email: <a href="mailto:theqalaevents@gmail.com">theqalaevents@gmail.com</a><br />
        Phone: +91- 9380204020 / +91- 9380204030
      </Paragraph>
    </Typography>
  );
};

export default PrivacyPolicy;
