import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

const TermsAndConditions = () => {
  return (
    <Typography style={{ padding: '20px' }}>
      <Title>Terms and Conditions</Title>

      <Title level={4}>1. Introduction</Title>
      <Paragraph>
        Welcome to Qala Events. These Terms and Conditions govern your use of our website and services. By accessing or using our website, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, you must not use our website or services.
      </Paragraph>

      <Title level={4}>2. Services</Title>
      <Paragraph>
        Qala Events offers comprehensive event management solutions, including but not limited to event planning, coordination, and execution. Our services are customized to meet your specific needs as outlined in your event contract.
      </Paragraph>

      <Title level={4}>3. Booking Payment, Cancellation and Refunds</Title>
      <Paragraph>
        Please review our Payment & refund Policy, which outlines payment, cancellation and refunds related details.
      </Paragraph>

      <Title level={4}>4. Changes to Services</Title>
      <Paragraph>
        Any changes to the agreed-upon services must be communicated in writing and may be subject to additional fees. We will do our best to accommodate changes, but availability cannot be guaranteed.
      </Paragraph>

      <Title level={4}>5. Liability</Title>
      <Paragraph>
        <Text strong>5.1 Limitation of Liability:</Text> Qala Events will not be liable for any indirect, incidental, or consequential damages arising out of or in connection with our services.
      </Paragraph>
      <Paragraph>
        <Text strong>5.2 Force Majeure:</Text> We are not responsible for any failure to perform our obligations due to events beyond our reasonable control, including but not limited to natural disasters, pandemics, or other unforeseen circumstances.
      </Paragraph>

      <Title level={4}>6. Client Responsibilities</Title>
      <Paragraph>
        You are responsible for providing accurate information and ensuring that all necessary permissions, licenses, and consents are obtained for your event. You agree to cooperate with us and provide any necessary assistance to ensure the successful execution of your event.
      </Paragraph>

      <Title level={4}>7. Intellectual Property</Title>
      <Paragraph>
        All content on our website, including text, graphics, logos, and images, are either the property of Qala Events or publicly available content. You may not use any content from our website without our prior written consent.
      </Paragraph>

      <Title level={4}>8. Privacy</Title>
      <Paragraph>
        Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and protect your personal information.
      </Paragraph>

      <Title level={4}>9. Governing Law</Title>
      <Paragraph>
        These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Your Country/State].
      </Paragraph>

      <Title level={4}>10. Changes to Terms</Title>
      <Paragraph>
        We reserve the right to update or modify these Terms and Conditions at any time. Any changes will be posted on our website, and it is your responsibility to review these terms periodically.
      </Paragraph>

      <Title level={4}>Contact Us</Title>
      <Paragraph>
        If you have any questions or concerns about these Terms and Conditions, please contact us at:
      </Paragraph>
      <Paragraph>
        <Text strong>Qala Events</Text><br />
        Email: <a href="mailto:theqalaevents@gmail.com">theqalaevents@gmail.com</a><br />
        Phone: +91- 9380204020 / +91- 9380204030
      </Paragraph>
    </Typography>
  );
};

export default TermsAndConditions;
