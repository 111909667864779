import { get, getDatabase, ref, set } from 'firebase/database'
import { firebaseApp } from '../utils/firebase'

const useFirebaseDB = () => {
    // Database Reference
    const database = getDatabase(firebaseApp())

    // Ref of DB connection
    const inquiriesRef = ref(database, 'inquiries')

    return { get, set, database, inquiriesRef }
}

export default useFirebaseDB
