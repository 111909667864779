// icons
import { MdOutlineSupportAgent } from "react-icons/md";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { TbCertificate } from "react-icons/tb";

// components
import WhyChooseUsCard from './why-choose-us-card'

// hooks
import { useTranslate } from '../../../hook'

export const WhyChooseUs = () => {
    const { strings, translate } = useTranslate()

    const whyChooseUs = [
        {
            id: '1',
            heading: translate(strings.bestPriceGuarantee),
            para: translate(strings.bestPriceGuaranteeDiscription),
            Icon: AiOutlineSafetyCertificate,
        },
        {
            id: '2',
            heading: translate(strings.easyQuickBooking),
            para: translate(strings.easyQuickBookingDiscription),
            Icon: MdOutlineSupportAgent,
        },
        {
            id: '3',
            heading: translate(strings.reliableQualityStandards),
            para: translate(strings.reliableQualityStandardsDiscription),
            Icon: TbCertificate,
        },
    ]

    return (
        <section className="container-1200 !py-10">
            <div className="mb-6">
                <h2 className="text-4xl font-bold text-center mb-2 text-main">
                    {translate(strings.whyChooseUs)}
                </h2>
                <p className="text-lg text-center">{translate(strings.whyChooseUsDescription)}</p>
            </div>
            <div className="flex justify-center items-start flex-wrap gap-8">
                {whyChooseUs.map((whyUs) => (
                    <WhyChooseUsCard key={whyUs.id} {...whyUs} />
                ))}
            </div>
        </section>
    )
}
