// Basic Info
export const MOBILE_NUMBER: string = `+91-9380204020`
export const SECOND_MOBILE_NUMBER: string = `+91-9380204030`
export const EMAIL: string = 'theqalaevents@gmail.com'
export const COMPANY_NAME: string = 'Qala Events'

// API Keys / Configs
export const GOOGLE_MAPS_API_KEY: string = 'AIzaSyBx9VhkJpp2q29P8sAj9dW8Zhd1uPiawjo'
export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyD0nH64oKMOnu0-nv_qFsv9sFfv-VRN8sc',
    authDomain: 'qala-events.firebaseapp.com',
    databaseURL: 'https://qala-events-default-rtdb.asia-southeast1.firebasedatabase.app/',
    projectId: 'qala-events',
    storageBucket: 'qala-events.appspot.com',
    messagingSenderId: '411171065558',
    appId: '1:411171065558:web:17469dcd0f378471440377',
    measurementId: 'G-YJH5XSPMMX',
}

// Links
export const WHATSAPP_WEB_LINK: string = `https://api.whatsapp.com/send?phone=${MOBILE_NUMBER}&text=Hi ${COMPANY_NAME}!!`
export const INTERNATIONALIZATION_LINK: string = '/languages/{{lng}}.json'
