import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const PaymentPolicy = () => {
  return (
    <Typography>
      <Title level={2}>{`Payment & Refund Policy`}</Title>
      <Paragraph>
        This Payment Policy outlines the terms and conditions regarding payments for the services provided by Qala Events. By using our services, you agree to comply with this Payment Policy.
      </Paragraph>
      <Title level={4}>1. Deposit</Title>
      <Paragraph>
        To secure our services, a non-refundable deposit is required at the time of booking. The amount of the deposit will be specified in your event contract.
      </Paragraph>
      <Title level={4}>2. Payment Schedule</Title>
      <Paragraph>
        The remaining balance is due according to the payment schedule outlined in your contract. Payments must be made by the specified dates to ensure the timely execution of your event.
      </Paragraph>
      <Title level={4}>3. Payment Methods</Title>
      <Paragraph>
        We accept payments via:
        <ul>
          <li>Credit card</li>
          <li>Bank transfer</li>
          <li>Other methods as specified in your contract</li>
        </ul>
      </Paragraph>
      <Title level={4}>4. Late Payments</Title>
      <Paragraph>
        Failure to make payments on time may result in additional fees or cancellation of services. Please refer to your contract for details on late payment fees and policies.
      </Paragraph>
      <Title level={4}>5. Refunds</Title>
      <Paragraph>
        Deposits are generally non-refundable. Refunds for any other payments will be processed according to the terms outlined in your contract and are subject to our cancellation policy.
      </Paragraph>
      <Title level={4}>Contact Us</Title>
      <Paragraph>
        If you have any questions or concerns about this Payment Policy, please contact us at:
      </Paragraph>
      <Paragraph>
        <strong>Qala Events</strong>
        <br />
        Email: <a href="mailto:theqalaevents@gmail.com">theqalaevents@gmail.com</a>
        <br />
        Phone: +91- 9380204020 / +91- 9380204030
      </Paragraph>
    </Typography>
  );
};

export default PaymentPolicy;
