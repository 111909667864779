import React from 'react';
import { Layout, Typography,  Divider, } from 'antd';

const { Header} = Layout;
const { Title, Paragraph } = Typography;

const Aboutus = () => {
  return (
    <Typography>
      <Header style={{ backgroundColor: '#fff', textAlign: 'center', padding: '20px 0' }}>
        <Title level={2}>Welcome to Qala Events</Title>
      </Header>
      
            
              <Title level={4}>About Us</Title>
              <Paragraph>
                At Qala Events, we believe that every event is a masterpiece, deserving of creativity, precision, and a personal touch. Founded with a passion for crafting unforgettable moments, Qala Events is your premier destination for comprehensive event management solutions. Our name, inspired by the Hindi word "Kala" which means art, embodies our commitment to transforming events into beautiful works of art.
              </Paragraph>
              <Divider />
              <Title level={4}>1. Our Mission</Title>
              <Paragraph>
                Our mission is simple: to turn your vision into reality. Whether it’s a housewarming, birthday celebration, baby shower, wedding, anniversary, or any other special occasion, we are dedicated to making your event truly exceptional. We combine creativity, meticulous planning, and flawless execution to ensure that every detail is perfect, leaving you free to enjoy your special moments.
              </Paragraph>
              <Divider />
              <Title level={4}>2. What We Do</Title>
              <Paragraph>
                From the initial consultation to the final farewell, Qala Events offers a full spectrum of event management services tailored to your unique needs. Our services include:
              </Paragraph>
              <Paragraph>
                <ul>
                  <li>Event Planning: Comprehensive planning to ensure every aspect of your event is covered.</li>
                  <li>Coordination: Seamless coordination on the day of the event, ensuring everything runs smoothly.</li>
                  <li>Design and Décor: Stunning designs and decorations that reflect your style and theme.</li>
                  <li>Vendor Management: Liaising with trusted vendors to provide top-notch services.</li>
                  <li>Entertainment: Arranging entertainment that captivates and delights your guests.</li>
                  <li>Logistics: Managing all logistical aspects to ensure a hassle-free experience.</li>
                </ul>
              </Paragraph>
              <Divider />
              <Title level={4}>3. Why Choose Qala Events?</Title>
              <Paragraph>
                <ul>
                  <li>Personalized Service: We take the time to understand your vision and tailor our services to meet your specific needs.</li>
                  <li>Professional Expertise: Our team of professional event organizers brings years of experience and expertise to every event.</li>
                  <li>Attention to Detail: We believe that the little details make the biggest difference, and we leave no stone unturned.</li>
                  <li>Creative Excellence: Our creative approach ensures that your event is not just an occasion but an unforgettable experience.</li>
                  <li>Reliability and Trust: We pride ourselves on our reliability and the trust we build with our clients.</li>
                </ul>
              </Paragraph>
              <Divider />
              <Title level={4}>4. Our Team</Title>
              <Paragraph>
                Our team is a dynamic group of creative professionals who are passionate about events. Each member of Qala Events brings a unique set of skills and expertise, ensuring that we can handle every aspect of your event with the utmost care and precision. We are dedicated to making your event a reflection of your dreams and aspirations.
              </Paragraph>
              <Divider />
              <Title level={4}>5. Our Promise</Title>
              <Paragraph>
                At Qala Events, we promise to deliver events that are not only successful but also memorable. We are committed to exceeding your expectations and creating moments that you and your guests will cherish forever.
              </Paragraph>
              <Divider />
              <Title level={4}>Get in Touch</Title>
              <Paragraph>
                Ready to start planning your next event? We’d love to hear from you! Contact us today to discuss your vision and see how we can bring it to life.
              </Paragraph>
              <Paragraph>
                <b>Email:</b> theqalaevents@gmail.com<br />
                <b>Phone:</b> +91- 9380204020 / +91- 9380204030
              </Paragraph>
    </Typography>
  );
};

export default Aboutus;
