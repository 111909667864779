export const strings: { [name: string]: string } = {
    wishlist: 'wishlist',
    cart: 'cart',
    profile: 'profile',
    needHelpReachOnUs: 'needHelpReachOnUs',
    yourEvents: 'yourEvents',
    yourWay: 'yourWay',
    headerMainBannerDescription: 'headerMainBannerDescription',
    newsletterTitle: 'newsletterTitle',
    newsletterDescription: 'newsletterDescription',
    occasion: 'occasion',
    city: 'city',
    locateMe: 'locateMe',
    date: 'date',
    noOfGuest: 'noOfGuest',
    enterYourNumber: 'enterYourNumber',
    whyChooseUs: 'whyChooseUs',
    whyChooseUsDescription: 'whyChooseUsDescription',
    bestPriceGuarantee: 'bestPriceGuarantee',
    bestPriceGuaranteeDiscription: 'bestPriceGuaranteeDiscription',
    easyQuickBooking: 'Easy Booking',
    easyQuickBookingDiscription: 'easyQuickBookingDiscription',
    reliableQualityStandards: 'reliableQualityStandards',
    reliableQualityStandardsDiscription: 'reliableQualityStandardsDiscription',
    discover: 'discover',
    discoverPara: 'discoverPara',
    addtocart: 'addtocart',
    addtocartPara: 'addtocartPara',
    placeorder: 'placeorder',
    placeorderPara: 'placeorderPara',
    deliverySetup: 'deliverySetup',
    deliverySetupPara: 'deliverySetupPara',
    celebrateinstyle: 'celebrateinstyle',
    celebrateinstylePara: 'celebrateinstylePara',
    yearsofExperience: 'yearsofExperience',
    expertPartners: 'expertPartners',
    satisfiedCustomers: 'satisfiedCustomers',
    products: 'products',
    letUsCraftYourEvent: 'letUsCraftYourEvent',
}
