import { FirebaseApp, initializeApp } from 'firebase/app'
import { FIREBASE_CONFIG } from '../../config'

// for storing instance of firebase
let instance: FirebaseApp | null = null

export const firebaseApp = () => {
    if (instance) {
        // returning firebase instance (if exists)
        return instance
    }
    // storing firebase instance
    instance = initializeApp(FIREBASE_CONFIG)
}
