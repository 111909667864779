import {
  annivarsary,
  babyShower,
  birthday,
  conference,
  milestone,
  houseWarming,
  festiveDecor,
  exquisite
} from '../assets/images/index'

const images = [
{image: annivarsary, text: 'Annivarsary'},
{image: babyShower, text: 'Baby Shower'}, 
{image: birthday, text: 'Birthday'}, 
{image: conference, text: 'Conference'},
{image: milestone, text: 'Milestone'}, 
{image: houseWarming, text: 'House Warming'}, 
{image: festiveDecor, text: 'Festive Decor'}, 
{image: exquisite, text: 'Exquisite'}
];

const ImageGrid = () => {
return (
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    {images.map((item, index) => (
      <div className="relative w-full h-[300px] group" key={index}>
        <img src={item.image} alt={`img-${index}`} className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-black opacity-30 group-hover:opacity-70 transition-opacity"></div>
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="text-white text-2xl font-bold">{item.text}</div>
        </div>
      </div>
    ))}
  </div>
);
};

export default ImageGrid;
