import { FC, ReactNode } from 'react'

// Router
import { BrowserRouter } from 'react-router-dom'

// Theme
import { ConfigProvider } from 'antd'

// utils
import { theme } from '../../utils/theme'

type CommonWrapperType = {
    children: ReactNode
}

const CommonWrapper: FC<CommonWrapperType> = ({ children }: CommonWrapperType) => {
    return (
        <BrowserRouter>
            <ConfigProvider theme={theme}>{children}</ConfigProvider>
        </BrowserRouter>
    )
}

export default CommonWrapper
