export const getCurrentDateTime = () => {
    const currentDate = new Date().toLocaleDateString('en-US', {})
    const currentTime = new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    })
    const currentDateTime = `${currentDate} ${currentTime}`

    return currentDateTime
}
