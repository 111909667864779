import FooterBottom from './footer-bottom'
import FooterTop from './footer-top'

const Footer = () => {
    return (
        <footer className="container-1200">
            <FooterTop />
            <FooterBottom />
        </footer>
    )
}

export default Footer
