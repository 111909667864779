// Components
// import { Header } from "./common/components/header";
import { Banner } from "./module/home/components/banner";
import { WhyChooseUs } from "./module/home/components/why-choose-us";
// import { Stats } from "./module/home/components/stats";
// import { Steps } from "./module/home/components/steps";
// import { Newsletter } from "./common/components/newsletter";
import { Footer } from "./common/components/footer";
import Spacer from "./common/components/spacer";
import ImageGrid from './module/ImageGrid';
import SingleImage from "./module/SingleImage";
import { Stats } from "./module/home/components/stats";

const App = () => {
  return (
    <>
      {/* <Header /> */}
      <SingleImage />
      {/* <Banner /> */}
      <Banner /> 
      {/* <Stats /> */}
      <ImageGrid />
      {/* <Steps /> */}
     

      <Spacer />
      <WhyChooseUs />
      {/* <Newsletter /> */}
      
      <Footer />
      
    </>
  );
};
export default App;
