// Images
// import {
//   HomeSectionMainImage1,
//   HomeSectionMainImage2,
//   HomeSectionMainImage3,
// } from "../../../assets/images";

// Components
import EventsSearchBar from "../../../common/components/events-search-bar/events-search-bar";
import { useTranslate } from "../../../hook";

export const Banner = () => {
  const { strings, translate } = useTranslate();

  return (
    <section className="w-full bg-gradient-to-b from-main/10 to-transparent overflow-hidden">
      <div className="container-1200 flex !py-10 gap-4 max-1080:flex-col">
        {/* Left Side - Tag line, CTA */}
        <div className="flex justify-center flex-col 1080:flex-[2] flex-1 max-1080:text-center">
          <h1 className="text-5xl my-8">
            <span className="text-main">{translate(strings.letUsCraftYourEvent)}</span>
          </h1>
          <p className="text-sm mb-8">
            {translate(strings.headerMainBannerDescription)}
          </p>
          {/* <div className="relative h-[80px] overflow-auto">
                        <div className="lg:absolute top-0 left-0"> */}
          <EventsSearchBar />
          {/* </div>
                    </div> */}
        </div>

        {/* Right Side - Images */}
        {/* <div className="1080:relative 1080:h-[350px] w-full flex-1 max-1080:hidden">
                    <div className="1080:absolute w-full h-full 1080:top-0 1080:right-0 flex max-1080:justify-center gap-3">
                        <img
                            src={
                              
                            }
                            className="1080:w-[350px] w-[300px] object-cover aspect-square rounded-lg"
                            alt="birthday event"
                        />
                        <img
                            src={HomeSectionMainImage3}
                            className="1080:w-[350px] w-[300px] object-cover aspect-square rounded-lg"
                            alt="birthday event"
                        />
                        <img
                            src={HomeSectionMainImage2}
                            className="1080:w-[350px] w-[300px] object-cover aspect-square rounded-lg"
                            alt="birthday event"
                        />
                    </div>
                </div> */}
      </div>
    </section>
  );
};
