import { message as toast } from 'antd'

const useToast = () => {
    toast.config({
        duration: 3,
        top: 10,
    })

    const successToast = (content: string = '') => {
        toast.success(content)
    }

    const errorToast = (content: string = '') => {
        toast.error(content)
    }

    const warningToast = (content: string = '') => {
        toast.warning(content)
    }

    const infoToast = (content: string = '') => {
        toast.info(content)
    }

    return { toast, successToast, errorToast, warningToast, infoToast }
}

export default useToast
