import { FC } from 'react'
import { IconType } from 'react-icons'

type WhyChooseUsCardType = {
    heading: string
    para: string
    Icon: IconType
    id: string
}

const WhyChooseUsCard: FC<WhyChooseUsCardType> = ({ heading, para, Icon }: WhyChooseUsCardType) => {
    return (
        <div className="flex flex-col justify-center max-md:w-full p-4 px-6 text-center  hover:shadow-xl cursor-default">
            <Icon className="w-10 h-10 mx-auto m-3 text-main" />
            <h2 className="text-xl font-semibold mb-1">{heading}</h2>
            <p className="md:max-w-60 text-sm text-black/70">{para}</p>
        </div>
    )
}

export default WhyChooseUsCard
