import { EMAIL, MOBILE_NUMBER, SECOND_MOBILE_NUMBER } from '../../../config'
import { ContactLink, IconLink, SupportLink } from './footer-links'
import {footerLogo} from '../../../assets/images'


const FooterTop = () => {
    const SupportLinks = [
        {
            title: 'Privacy Policy',
           
        },
        {
            title: 'Terms & Condition',
          
        },
        {
            title: 'Payment & Refund Policy',
           
        },
    ]

    const CompanyLinks = [
        {
            title: 'About Us',
        },
    ]

    const ContactLinks = [
        {
            title: '',
            value: MOBILE_NUMBER,
            link: `tel:${MOBILE_NUMBER}`,
        },
        {
            value: SECOND_MOBILE_NUMBER,
            link: `tel:${SECOND_MOBILE_NUMBER}`,
        },
        {
            title: 'Email Us',
            value: EMAIL,
            link: `mailto:${EMAIL}`,
        },
    ]

    // const ContactIconsLinks = [
    //     {
    //         title: 'LinkedIn',
    //         Icon: FaLinkedin,
    //         link: `https://www.linkedin.com`,
    //     },
    //     {
    //         title: 'Instagram',
    //         Icon: FaInstagram,
    //         link: `https://www.instagram.com`,
    //     },
    //     {
    //         title: 'Youtube',
    //         Icon: FaYoutube,
    //         link: `https://www.youtube.com`,
    //     },
    // ]

    return (
        <div className="grid grid-cols-3 max-sm:grid-cols-2 gap-4 py-6">
            <div>
                <p className="font-semibold text-xl mb-5">Company</p>
                {CompanyLinks.map((link) => (
                    <SupportLink key={link.title} title={link.title} />
                ))}
                <img src={footerLogo} alt={`img`} className="w-[120px] h-[120px]" />
            </div>
            <div>
                <p className="font-semibold text-xl mb-5">Support</p>
                {SupportLinks.map((link) => (
                    <SupportLink key={link.title} title={link.title} />
                ))}
            </div>
            <div className="max-md:col-span-2">
                <p className="font-semibold text-xl mb-5">Contact us</p>
                {ContactLinks.map((link) => (
                    <ContactLink key={link.value} {...link} />
                ))}
                {/* <div className="flex gap-4 mt-6">
                    {ContactIconsLinks.map((link) => (
                        <IconLink key={link.title} {...link} />
                    ))}
                </div> */}
            </div>
        </div>
    )
}

export default FooterTop
